import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ContentsList from '../../../components/ContentsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="タイポグラフィ - スタイル" mdxType="SEO" />
    <PageTitle title="タイポグラフィ" enTitle="Typography" mdxType="PageTitle" />
    <p>{`Amebaらしさを言葉で伝えるために、その感情の下地となるタイポグラフィの定義をしています。`}</p>
    <ContentsList list={[{
      url: "/styles/typography/brand/",
      title: "ブランドタイポグラフィ",
      enTitle: "Brand Typography",
      summary: "Amebaらしさと、読みやすさ、認識しやすさを意識した書体を掲載しています。"
    }, {
      url: "/styles/typography/ui/",
      title: "UIタイポグラフィ",
      enTitle: "UI Typography",
      summary: "Amebaが提供するデジタルコンテンツの中で使用される、UI上での読みやすさを考慮した書体選びや表現について定義しています。"
    }]} mdxType="ContentsList"></ContentsList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      